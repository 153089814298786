import { createContext, useState, useEffect, useCallback } from "react";

import { useCookies } from "react-cookie";

import { UserService } from "services/user/user.service";

import SignInHashDto from "dto/auth/signinhash.dto";
import DateTools from "tools/utils/date.tools";
import GeneralRepository from "repositories/general.repository";
import { RouteTools } from "tools/utils/routetools";
import { Loading } from "components/elements/loading/Loading";
import { UserSettingsService } from "services/user/usersettings.service";

import { Types } from "tools/types/types";
import { Status } from "tools//types/status";
import { isTokenExpired } from "tools/utils/commontools";
import IProvider from "interfaces/provider.interface";
import SocialSignInDto from "dto/auth/socialsignin.dto";
import { Config } from "tools/utils/config";
import SignInDto from "dto/auth/signin.dto";

import { RegisterUserDto } from "dto/auth/registeruser.dto";

type Props = {
  user: any;
  roles: any;
  usersettings: any;
  signIn: (signInDto: SignInDto, r?: boolean) => void;
  signInSocial: (socialSignInDto: SocialSignInDto) => void;
  signOut: (onlyUser?: boolean) => void;
  verifyToken: () => void;
  updateUserSettings: (field: string, value: any) => void;
  signUp: (signUpDto: RegisterUserDto) => void;
  registerTeacher: (obj: any) => void;
  setUserInterface: (interfaceType: number) => void;
  userInterface: number;
  teacher: any;
  student: any;
  setNextLocation: (location: any) => void;
  setStudent: (student: any) => void;
  logout: () => void;
};

export const AuthContext = createContext<Props>({
  user: null,
  roles: null,
  usersettings: null,
  signIn: () => {},
  signInSocial: () => {},
  signOut: () => {},
  verifyToken: () => {},
  updateUserSettings: () => {},
  signUp: () => {},
  registerTeacher: () => {},
  setUserInterface: () => {},
  userInterface: Types.STUDENT_INTERFACE,
  teacher: null,
  student: null,
  setNextLocation: () => {},
  setStudent: () => {},
  logout: () => {},
});
var inRefresh = false;
const userService = new UserService();
const userSettingsService = new UserSettingsService();
export const AuthProvider: React.FC<IProvider> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const [token, setToken] = useState<any>(null);
  const [jwt, setJWT] = useState(null);

  const [mustchangepassword, setMustChangePassword] = useState(false);

  const [nextLocation, setNextLocation] = useState<any>(false);
  const [user, setUser] = useState<any>(-1);
  const [roles, setRoles] = useState<any>(null);
  const [teacher, setTeacher] = useState<any>(null);
  const [usersettings, setUserSettings] = useState<any>(null);
  const [student, setStudent] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [userInterface, setUserInterface] = useState(Types.STUDENT_INTERFACE);
  // ---------------------

  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
    if (user && user !== -1 && !checkCheckPasswordInit()) {
      handleMustChangePassword();
    }
  }, [user, roles, usersettings]);

  const generate = useCallback(() => {
    if (token === null || token === undefined) return;

    if (jwt && isTokenExpired(jwt))
      userService.generateToken(handleSetToken, {});
    else if (token == "") userService.generateToken(handleSetToken, {});
  }, [token, jwt]);

  useEffect(() => {
    generate();
  }, [generate]);

  const handleMustChangePassword = () => {
    if (mustchangepassword) {
      if (user.hasOwnProperty("statuspassword")) {
        if (user.statuspassword === Status.TEMPORARY) {
          RouteTools.setHistory("/changetemporarypassword", {});
        }
      }
    }

    setCookie("checkPasswordInit", true, { path: "/" });
  };

  const checkCheckPasswordInit = () => {
    const t =
      cookies &&
      cookies.checkPasswordInit != undefined &&
      cookies.checkPasswordInit != "undefined" &&
      cookies.checkPasswordInit != "null"
        ? cookies.checkPasswordInit
        : false;

    // //logger("checkCheckPasswordInit", t);

    return t;
  };

  const checkIsLoading = () => {
    if (!isLoading) return;

    // // //logger(
    //   "authcheckIsLoadingauthcheckIsLoadingauthcheckIsLoadingauthcheckIsLoading",
    //   user,
    //   roles
    // );

    if (!isLoading) return;
    if (user == -1) return;
    if (!token) return;
    if (!jwt) return;
    // if (roles == -1) return;

    setIsLoading(false);
  };

  // ----------------------------------------

  useEffect(() => {
    GeneralRepository.setHandleUnauthorized(handleUnauthorized);
    checkToken();
  }, []);

  useEffect(() => {
    RouteTools.setUserInterface(getUserInterface);
    RouteTools.setUserInterfaceFunction(setUserInterface);
  }, [userInterface]);

  const getUserInterface = () => {
    return userInterface;
  };

  // ---------------------
  const checkToken = () => {
    var t =
      cookies.token != undefined &&
      cookies.token != "undefined" &&
      cookies.token != "null"
        ? cookies.token
        : "";

    processToken(t);
  };

  const processToken = (accesstoken: any) => {
    setToken(accesstoken);
    setCookie("token", accesstoken, { path: "/" });

    if (accesstoken.accesstoken != undefined) {
      setCookie("jwt", accesstoken.accesstoken, { path: "/" });
      setJWT(accesstoken.accesstoken);
    }
  };

  const verifyToken = useCallback(() => {
    if (!token) return;

    const expiresin = token.expiresin;
    const untildate = token.untildate;
    const diff = expiresin * 0.1;

    const c = DateTools.getTimeStamp();
    const rd = untildate - c;

    if (rd > diff || rd < 0) {
      if (rd < 0) {
        // signOut(true);
        // if (isTokenExpired(jwt)) generate();
      }
      return;
    }
    if (isTokenExpired(jwt)) generate();
    else refreshToken();
  }, [token, jwt]);

  const getToken = useCallback(() => {
    verifyToken();
    return jwt;
  }, [jwt, verifyToken]);

  useEffect(() => {
    GeneralRepository.setToken(getToken);
  }, [getToken]);

  const refreshToken = () => {
    if (inRefresh) return;
    inRefresh = true;

    const cbparameters: any = {};
    cbparameters.jwt = jwt;

    userService.refreshToken(handleSetToken, cbparameters);
  };
  // ---------------------

  // ---------------------
  const logout = () => {
    userService.logout(handleSetToken, { cbFunction: signOut });
  };
  // ---------------------
  const signOut = (onlyUser?: boolean) => {
    onlyUser = onlyUser ?? false;
    setUser(false);
    setRoles(null);
    setUserSettings(null);
    setMustChangePassword(false);
    setTeacher(null);
    setStudent(null);

    if (!onlyUser) removeCookie("_r");
    if (!onlyUser) removeCookie("_uid");
    if (!onlyUser) removeCookie("_uhash");

    if (!onlyUser) removeCookie("checkPasswordInit");
    if (!onlyUser) setUserInterface(Types.STUDENT_INTERFACE);
    if (!onlyUser) RouteTools.setHistory("/login", {});
  };

  const checkUser = useCallback(() => {
    if (!token) return;
    if (!jwt) return;

    if (user != undefined && user != null && user != -1) return;

    setSavedUser();
  }, [token, jwt, user]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);
  // ---------------------
  const setSavedUser = () => {
    // //logger("Resultsignup_handleSetToken 111111111");
    const _uid =
      cookies._uid != undefined &&
      cookies._uid != "undefined" &&
      cookies._uid != "null"
        ? cookies._uid
        : "";

    const _uhash =
      cookies._uhash != undefined &&
      cookies._uhash != "undefined" &&
      cookies._uhash != "null"
        ? cookies._uhash
        : "";

    const _r =
      cookies._r != undefined &&
      cookies._r != "undefined" &&
      cookies._r != "null"
        ? cookies._r
        : 0;

    const r = _r ? true : false;

    if (!_uid || !_uhash) {
      setUser(false);
      return;
    }

    const signInHashDto = new SignInHashDto();
    signInHashDto.id = _uid;
    signInHashDto.hash = _uhash;

    const cbparameters: any = {};
    cbparameters.remember = r;

    // //logger("handleSetToken signInHash", signInHashDto, cbparameters);
    userService.signInHash(signInHashDto, handleSetToken, cbparameters);
  };
  // ---------------------

  
  const processUser = (obj: any, cbparameters: any) => {
    if (
      cbparameters.remember != undefined &&
      cbparameters.remember != null &&
      cbparameters.remember
    ) {
      let exp = Config.COOKIE_EXPIRES;

      if (isNaN(exp)) exp = 0;

      setCookie("_r", "1", { path: "/", expires: DateTools.getDate(exp) });
      setCookie("_uid", obj.id, { path: "/", expires: DateTools.getDate(exp) });
      setCookie("_uhash", obj.hash, {
        path: "/",
        expires: DateTools.getDate(exp),
      });
    } else {
      setCookie("_r", "0", { path: "/" });
      setCookie("_uid", obj.id, { path: "/" });
      setCookie("_uhash", obj.hash, { path: "/" });
    }
  };

  const handleUnauthorized = () => {
    removeCookie("_r");
    removeCookie("_uid");
    removeCookie("_uhash");
    removeCookie("checkPasswordInit");
    setUser(false);
    setRoles(null);
    setUserSettings(null);
    setMustChangePassword(false);
    setTeacher(null);
    setStudent(null);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleSetToken = (result: any, cbparameters: any) => {
    // //logger("handleSetToken", result);
    if (!result) return;
    if ((!result || (result && result.err)) && user) {
      handleUnauthorized();
      return;
    }
    if (result.accesstoken != undefined && result.accesstoken != null) {
      processToken(result.accesstoken);
    }

    if (
      result.mustchangepassword != undefined &&
      result.mustchangepassword != null
    ) {
      setMustChangePassword(result.mustchangepassword);
    }
    if (result.obj != undefined && result.obj != null) {
      setUser(result.obj);
      processUser(result.obj, cbparameters);
    }
    if (result.roles != undefined && result.roles != null) {
      setRoles(result.roles);
    }
    if (result.usersettings != undefined && result.usersettings != null) {
      setUserSettings(result.usersettings);
    }
    if (
      result.hasOwnProperty("teacher") &&
      result.teacher != undefined &&
      result.teacher != null
    ) {
      setTeacher(result.teacher);
    }
    if (
      result.hasOwnProperty("student") &&
      result.student != undefined &&
      result.student != null
    ) {
      setStudent(result.student);
    }

    if (cbparameters && cbparameters.goToTeacher) {
      if (teacher || result.teacher) {
        RouteTools.setHistory("/teacherinterface", {});
      } else {
        RouteTools.setHistory("/", {});
      }
    }
    if (cbparameters && cbparameters.defaultRoute) {
      if (nextLocation) {
        const url = nextLocation;
        setNextLocation(false);
        RouteTools.setHistory(url, {});
      } else {
        RouteTools.setHistory("/", {});
      }
    }

    if (cbparameters && cbparameters.cbFunction) {
      cbparameters.cbFunction();
    }

    inRefresh = false;
  };

  const updateUserSettings = (field: string, value: any) => {
    if (usersettings == undefined) return;
    if (usersettings == null) return;

    const t = usersettings;

    t[field] = value;
    userSettingsService.update(t.id, undefined, {}, t);

    setUserSettings(t);
  };

  // ---------------------

  // ---------------------

  const signInSocial = (socialSignInDto: SocialSignInDto) => {
    // r = r != undefined ? r : false;

    const cbp: any = {};
    // cbp.remember = r;
    cbp.defaultRoute = true;
    setUserInterface(Types.STUDENT_INTERFACE);
    userService.signInSocial(socialSignInDto, handleSetToken, cbp);
  };

  const signIn = (signInDto: SignInDto, r?: boolean) => {
    r = r != undefined ? r : false;

    const cbp: any = {};
    cbp.remember = r;
    cbp.defaultRoute = true;
    setUserInterface(Types.STUDENT_INTERFACE);
    userService.signIn(signInDto, handleSetToken, cbp);
  };

  const signUp = (signUpDto: RegisterUserDto) => {
    if (userInterface !== Types.TEACHER_INTERFACE)
      setUserInterface(Types.STUDENT_INTERFACE);
    userService.register(signUpDto, handleSetToken, { defaultRoute: true });
  };

  const registerTeacher = (obj: any) => {
    setIsLoading(true);
    userService.registerTeacher(obj, handleSetToken, { goToTeacher: true });
  };

  const value = {
    user,
    roles,
    usersettings,
    signIn,
    signInSocial,
    signOut,
    verifyToken,
    updateUserSettings,
    signUp,
    registerTeacher,
    setUserInterface,
    userInterface,
    teacher,
    student,
    setNextLocation,
    setStudent,
    logout,
  };

  return isLoading ? (
    <Loading />
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
