import React, { useCallback, useLayoutEffect } from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "providers/ResourceProvider";

import { AuthProvider } from "providers/AuthProvider";
import { HeadProvider } from "providers/HeadProvider";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "providers/MessageProvider";
import { AppRoutes } from "providers/AppRoutes";
import { SupportArticleProvider } from "context/supportarticle.context";
import GeneralRepository from "repositories/general.repository";
import { ServerError } from "components/general/ServerError";
import { MyGoogleOAuthProvider } from "providers/MyGoogleOAuthProvider";

function App() {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  useEffect(() => {
    setTheme(theme);
  }, []);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const processServerError = () => {
    setServerError(true);
  };

  const isServerError = useCallback(() => {
    return serverError;
  }, [serverError]);

  
  useLayoutEffect(() => {
    GeneralRepository.setReloadFunction(handleReload);
    GeneralRepository.setServerErrorFunction(processServerError);
  }, []);

  useEffect(() => {
    GeneralRepository.setIsServerError(isServerError);
  }, [isServerError]);
  
  if (serverError) {
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );
  }
  return (
    <MyGoogleOAuthProvider>
      <ThemeProvider theme={_theme}>
        <CookiesProvider>
          <HeadProvider>
            <AuthProvider>
              <ResourceProvider>
                <MessageProvider>
                  <SupportArticleProvider>
                    <AppRoutes />
                  </SupportArticleProvider>
                </MessageProvider>
              </ResourceProvider>
            </AuthProvider>
          </HeadProvider>
        </CookiesProvider>
      </ThemeProvider>
    </MyGoogleOAuthProvider>
  );
}

export default App;
