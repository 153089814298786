import { LabelTypes } from "tools/types/labeltypes";

export class Config {
  public static HEADER_HEIGHT = 90;
  public static FOOTER_HEIGHT = 282;

  public static FB_CONSOLE_APP_ID =
    process.env.REACT_APP_FBCONSOLE_APP_ID || "";

  public static GOOGLE_CONSOLE_CLIENT_ID = process.env.REACT_APP_GOOGLECONSOLE_CLIENT_ID || "";
  public static CLOSE_MESSAGE_SUCCESS =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_SUCCESS) || 10000;
  public static CLOSE_MESSAGE_WARNING =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_WARNING) || 7000;
  public static CLOSE_MESSAGE_ERROR =
    Number(process.env.REACT_APP_AUTOMATED_CLOSEMESSAGE_ERROR) || 5000;

  public static RECAPTCHA_SITE_KEY =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

  public static COOKIE_EXPIRES =
    Number(process.env.REACT_APP_USERCOOKIES_EXPIRE) || 100000;

    public static LABEL_TYPE = Number(process.env.REACT_APP_LABEL_TYPE) || LabelTypes.LABEL_SITE;

    public static DEFAULT_LANGUAGE =
    process.env.REACT_APP_DEFAULT_LANGUAGE || "RO";

    public static MAX_RELOAD_COUNT = Number(process.env.REACT_APP_MAX_RELOAD_COUNT) || 5;

    public static WWW_SERVER = process.env.REACT_APP_WWW_SERVER || "";
    public static API_SERVER = process.env.REACT_APP_API_SERVER || "";

    public static COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE || "+40";
}
